<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Fixed Accounts (Blacklisted)</h1>
      <div class="flex justify-end items-end">
        <button
          class="bg-blue-600 py-3 px-6 rounded-md text-white"
          @click="downloadCsv"
        >
          Download CSV
        </button>
      </div>
      <div class="flex items-center justify-between">
        <input
          type="text"
          class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
          name=""
          placeholder="Search by name, account number, ID"
          id=""
          v-model="search"
        />
      </div>
      <div class="mt-14">
        <button
          class="bg-gray-300 text-black text-xs py-3 px-6 rounded-md text-black mb-3 font-semibold"
          @click="whitelistSelected"
        >
          Whitelist Selected ({{ selected.length }})
        </button>
      </div>
      <div style="max-height: 600px; overflow: scroll">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <tr>
            <th
              class="text-l p-3 text-left"
              colspan="11"
              style="background: #dee6f2"
            >
              Merchant Information
            </th>
          </tr>
          <tr>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              <input type="checkbox" v-model="selectAll" />
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Merchant ID
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Business Name
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Business Email
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Bank Name
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Account Name
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Bank Account
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Store
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Date Account Created
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Blacklist Status
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Action
            </th>
          </tr>
          <tr class="text-xs" v-for="(account, i) in filterAccounts" :key="i">
            <td>
              <input type="checkbox" :value="account.id" v-model="selected" />
            </td>
            <td>{{ account.business_id }}</td>
            <td>{{ account.business_name }}</td>
            <td>{{ account.business_email }}</td>
            <td>{{ account.bank_name }}</td>
            <td>{{ account.custom_account_name }}</td>
            <td>{{ account.custom_account_number }}</td>
            <td>{{ account.store_details.store_name }}</td>
            <td>
              {{ moment(account.created_at).format("DD MMM YYYY - h:mm:ss a") }}
            </td>
            <td>{{ account.black_list_status }}</td>
            <td>
              <button
                :class="
                  account.black_list_status === true
                    ? 'bg-gray-300 text-black text-xs'
                    : 'bg-red-900 text-white text-xs'
                "
                class="py-3 px-6 rounded-md"
                @click="toggleBlacklist(account.id, !account.black_list_status)"
              >
                {{
                  account.black_list_status === true ? "Whitelist" : "Blacklist"
                }}
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black'
              : 'bg-blue-900 text-white'
          "
        >
          &laquo; Prev
        </button>

        <button
          v-if="filterAccounts.length > 99"
          @click="nextPage(currentPage)"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { json2excel } from "js2excel";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      currentPage: 1,
      isDisabled: true,
      search: "",
      accounts: null,
      selected: [],
      selectedWhitelist: [],
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["GET_BLACKLISTED_FIXED_ACCOUNT_RES"]),
    filterAccounts() {
      //let y = this.GET_DOCUMENTS.data.filter((b) => b.status === x);
      if (
        this.GET_BLACKLISTED_FIXED_ACCOUNT_RES.data.items.fixAccount !== null
      ) {
        return this.GET_BLACKLISTED_FIXED_ACCOUNT_RES.data.items.fixAccount.filter(
          (account) =>
            account.custom_account_number.includes(this.search) ||
            account.custom_account_name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            account.business_id
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            account.business_name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            account.business_email
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            account.bank_name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            account.store_details.store_name
              .toLowerCase()
              .includes(this.search.toLowerCase())
        );
      }
      // this.documents = filtered;
    },
    selectAll: {
      get: function () {
        return this.GET_BLACKLISTED_FIXED_ACCOUNT_RES.data.items.fixAccount
          ? this.selected.length ==
              this.GET_BLACKLISTED_FIXED_ACCOUNT_RES.data.items.fixAccount
                .length
          : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          if (
            this.GET_BLACKLISTED_FIXED_ACCOUNT_RES.data.items.fixAccount !==
            null
          ) {
            this.GET_BLACKLISTED_FIXED_ACCOUNT_RES.data.items.fixAccount.forEach(
              function (account) {
                selected.push(account.id.toString());
              }
            );
          }
        }

        this.selected = selected;
      },
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    this.fetchRecords();
  },
  methods: {
    ...mapActions([
      "FETCH_BLACKLISTED_FIXED_ACCOUNTS",
      "BLACKLIST_FIXED_ACCOUNTS",
      "WHITELIST_BULK_FIXED_ACCOUNTS",
      "FETCH_PAGINATED_BLACKLISTED_FIXED_ACCOUNTS",
    ]),
    async fetchRecords() {
      this.isLoading = true;
      let response = await this.$store.dispatch(
        "FETCH_BLACKLISTED_FIXED_ACCOUNTS"
      );
      this.accounts = response.data;

      if (response.data) {
        this.isLoading = false;
      }
    },
    downloadCsv() {
      let data = this.GET_FIXED_ACCOUNT_RES.data.items.map((account) => {
        return {
          "Merchant Id": account.business_id,
          "Business Name": account.business_name,
          "Business Email": account.business_email,
          "Bank Name": account.bank_name,
          "Account Name": account.custom_account_name,
          "Account Number": account.custom_account_number,
          "Store Name": account.store_details.store_name,
          "Date Store Created": account.store_details.created_at,
        };
      });
      json2excel({
        data,
        name: "Fixed Accounts (Blacklisted)",
        // formateDate: this.moment().format("DD MMM YYYY - h:mm:ss a"),
      });
    },
    async toggleBlacklist(business_id, status) {
      let res = confirm("Are you sure you want to blacklist this account?");
      if (res === true) {
        this.isLoading = true;
        let data = { fixed_account_id: business_id, black_list_status: status };
        try {
          let res = await this.$store.dispatch(
            "BLACKLIST_FIXED_ACCOUNTS",
            data
          );
          if (res.status == true) {
            alert("Backlist was successful");
            this.$router.go();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}

        this.isLoading = false;
      }
    },
    async whitelistSelected() {
      let res = confirm("Are you sure you want to whitelist these accounts?");
      if (res === true) {
        this.isLoading = true;
        this.selected.map((el) => {
          const selecteObj = {
            fixed_account_id: el,
            black_list_status: false,
          };
          this.selectedWhitelist.push(selecteObj);
        });
        try {
          let res = await this.$store.dispatch(
            "WHITELIST_BULK_FIXED_ACCOUNTS",
            this.selectedWhitelist
          );
          if (res.status == true) {
            alert("Whitelisting was successful");
            this.$router.go();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}

        //this.isLoading = true;
        //let data = { fixed_account_id: business_id, black_list_status: false };
      }
    },

    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_BLACKLISTED_FIXED_ACCOUNTS",
        currPage - 1
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_BLACKLISTED_FIXED_ACCOUNTS",
        currPage + 1
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
